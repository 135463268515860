.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

.title,
.subtitle {
  font-family: var(--ff-bold);
  color: var(--primary);
  line-height: 24px;
  text-align: center;
}

.title {
  font-size: 32px;
  margin-bottom: 12px;
}